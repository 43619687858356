<script>
import Vue from 'vue'
import Switcher from "@/components/switcher";
import VueRecaptcha from 'vue-recaptcha';
import { v4 as uuidv4 } from 'uuid'
import duppla from '@/duppla'
import axios from 'axios'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
//import AudioRecorder from 'vue-audio-recorder'
//import VueDictaphone from "vue-dictaphone";
import DictaphoneLocal from "@/components/dictaphone-local";
//import VueRecord from '@codekraft-studio/vue-record'
import moment from "moment";
//import Spinner from 'vue-spinkit'
import VueMonthlyPicker from 'vue-monthly-picker'
import WhatsappWidget from "@/components/whatsapp-widget";
import Cloudinary from 'cloudinary-vue'
import { setWithExpiration, getWithExpiration } from '@/services/storageResultsService';

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

Vue.use(Cloudinary)

import {
  HomeIcon,
  UserIcon,
  UserCheckIcon,
  MailIcon,
  KeyIcon,
  PhoneForwardedIcon,
  PhoneIcon,
  FlagIcon,
  FolderIcon,
  PaperclipIcon,
  XCircleIcon,
  CalendarIcon,
  HashIcon,
  PlusIcon,
  XIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  MicIcon,
  MicOffIcon, StopCircleIcon
} from "vue-feather-icons";

const RESULTS_SAVE_TAG = process.env.VUE_APP_RESULTS_SAVE_TAG;

export default {
  /*
  metaInfo: {
    title: 'Checkup Digital de cáncer - '
  },
  */
  components: {
    Navbar,
    Footer,
    Switcher,
    VueRecaptcha,
    VueMonthlyPicker,
    HomeIcon,
    UserIcon,
    UserCheckIcon,
    MailIcon,
    KeyIcon,
    PhoneForwardedIcon,
    PhoneIcon,
    FlagIcon,
    FolderIcon,
    PaperclipIcon,
    XCircleIcon,
    CalendarIcon,
    HashIcon,
    PlusIcon,
    XIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    MicIcon,
    MicOffIcon, StopCircleIcon,
    WhatsappWidget
  },
  data() {
    return {
      monthLabels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],
      loadingMedicalRecord: true,
      errorForm: false,
      loadingContactForm: false,
      validOrganization: false,
      validOrganizationAndStart: false,
      savingForm: false,
      savingOk: false,
      wCurrentStep: 0,
      user: { fields:{contact_email: ''} },
      form: {symptoms: [], direct_relatives: [], opt_advice: 'yes', contact_phone_country: process.env.VUE_APP_DEFAULT_PH_COUNTRY},
      countries: [],
      reCaptchaMissing: false,
      reCaptchaMissingContactForm: false,
      errorContactForm: false,
      successContactForm: false,
      reCaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_KEY,
      organization: null,
      contactForm: {
        email: "",
        comments: "Estoy interesado en el checkup digital de cáncer"
      }
    }
  },
  computed: {
    organizationUuid () {
      return this.$route.query.id
    },
    reshareOrigin () {
      return this.$route.query.o
    },
    reshareDepthLevel () {
      return this.$route.query.dl
    },
    greeting () {
        var day = new Date();
        var hr = day.getHours();
        if (hr >= 0 && hr < 12) {
            return("¡Buenos días!");
        } else if (hr >= 12 && hr <= 18) {
            return("¡Buenas tardes!");
        } else {
            return("¡Buenas noches!");
        }
    },
    wSteps () {
      let steps = [
        {
          form: "init",
          submitForm: true,
          text: `
Arranquemos tu prevención del cáncer.<br/><br/>
Comparte tus datos para enviarte la información.
`
        },{
          form: "symptoms_step",
          submitForm: true,
          text: `
Un cáncer es una célula que crece de forma descontrolada.<br/>
¿Qué detona este crecimiento? <br/> Hay diferentes factores que exploraremos en este cuestionario, pero primero, compártenos.`
        },{
          form: "ahf",
          submitForm: true,
          text: `
El primer tipo de factor de riesgo es hereditario. Conocer si hay casos de cáncer en nuestra familia, puede indicar si este factor nos impacta. `
        },{
          form: "agine",
          submitForm: true,
          text: `
Existen factores de riesgo hormonales, así como de exposición a virus transmitidos por actividad sexual. Estas preguntas nos ayudarán a conocer su impacto en tu perfil de riesgo.`
        },{
          form: "apnp",
          submitForm: true,
          text: `
Las decisiones de estilo de vida también impactan tu factor de riesgo de desarrollo de cáncer. `
        },{
          form: "comorbidities",
          submitForm: true,
          text: `
Un factor adicional es la presencia de otras enfermedades, como diabetes, hiperlipidemia u obesidad.`
        },{
          form: "end",
          submitForm: true,
          text: ``
        }
      ]
      if (this.form.patient_sex === 'male' || (this.form.patient_sex === 'female' && this.form.user_age < 18)) {
        steps.splice(3, 1) // agine
      }
      
      return steps
    },
    wTotalSteps () {
      return this.wSteps.length - 1
    },
    wProgress () {
      return (this.wCurrentStep / this.wTotalSteps) * 100
    },
    wFirstStep () {
      if (this.wCurrentStep === 0) {
        return true
      } else {
        return false
      }
    },
    wLastStep () {
      if (this.wCurrentStep === this.wTotalSteps) {
        return true
      } else {
        return false
      }
    },
    currYear () {
      return moment().year()
    },
    currDate () {
      return moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    reCaptchaVerified (response) {
      this.form.g_recaptcha_response = response
    },
    reCaptchaExpired () {
      this.form.g_recaptcha_response = null
    },
    reCaptchaVerifiedContactForm (response) {
      this.contactForm.g_recaptcha_response = response
    },
    reCaptchaExpiredContactForm () {
      this.contactForm.g_recaptcha_response = null
    },
    wStepIndex(currentStep) {
      for(var i=0; i<this.wSteps.length; i++) {
        if(this.wSteps[i].form === currentStep) {
          return i
        }
      }
      return 0
    },
    wStepName(index) {
      return this.wSteps[index].form
    },
    nextStep() {
      if(this.wCurrentStep < this.wTotalSteps){
        var stepsForward = 1
        /*if (this.user.fields.payment && (this.wCurrentStep + 1) == this.wPaymentStep){
          stepsForward = 2
        }*/
        var currentForm = this.$refs['form_' + this.wStepName(this.wCurrentStep)]
        if(currentForm) {
          if(currentForm.checkValidity()){
            var originalStep = this.wCurrentStep
            console.log("🚀 ~ formData ~ currentForm.elements:", this.form)
            // montón.identify(this.form.contact_email)

            
            this.wCurrentStep += stepsForward
            this.goto('init')
            //Todo se hara en un mismo momento, no es necesario para el cuestionario
            /*if (this.wSteps[originalStep].lastStep) {
              this.form.form_completed_date = new Date()
              this.form.status = '1'
            }
            this.submit().then(() => {
              delete this.form.form_completed_date
              delete this.form.status
              this.getLinkedLists()
            })
            */
          } else {
            currentForm.reportValidity()
          }
        } else {
          // if no form then nothing to validate, just submit to save step
          this.wCurrentStep += stepsForward
          this.goto('init')
          //Todo se hara en un mismo momento, no es necesario para el cuestionario
          //this.submit()
        }
      }
    },
    previousStep() {
      if(this.wCurrentStep > 0){
        var stepsBack = 1
        /*if (this.user.fields.payment && (this.wCurrentStep - 1) == this.wPaymentStep){
          stepsBack = 2
        }*/
        this.wCurrentStep -= stepsBack
        this.goto('init')
      }
      return
    },
    submit () {
      if (!this.savingForm){
        this.errorForm = false
        this.savingForm = true
        this.reCaptchaMissing = false

        var currentForm = this.$refs['form_end']

        if (currentForm.checkValidity()) {
          if (!this.form.g_recaptcha_response) {
            this.reCaptchaMissing = true
            this.savingForm = false
            return
          }
        
          //Object.assign(cleanForm, this.form)
          let cleanForm = JSON.parse(JSON.stringify(this.form));

          if (this.form.opt_advice === 'no') {
            delete cleanForm.contact_phone
            delete cleanForm.contact_phone_country
          }
          //Airtable linked records
          if (cleanForm.contact_phone_country) {
            cleanForm.contact_phone_country = [cleanForm.contact_phone_country]
          }
          if (cleanForm.patient_country) {
            cleanForm.patient_country = [cleanForm.patient_country]
          }
          
          // reshare origin
          if (this.reshareOrigin) {
            cleanForm.reshare_origin = [this.reshareOrigin]
          }
          // reshare depth level
          if (this.reshareDepthLevel) {
            if (!isNaN(this.reshareDepthLevel)) {
              cleanForm.reshare_level = Number(this.reshareDepthLevel)
            }
          }

          cleanForm.organization = [this.organization.id]

          if (cleanForm.patient_sex === 'male') {
            delete cleanForm.f_menstruation_age
            delete cleanForm.ivs
            delete cleanForm.l_menstruation_age
            delete cleanForm.contraceptives
            delete cleanForm.pregnant_currently
            delete cleanForm.previous_pregnancies
            delete cleanForm.previous_pregnancies_qty
            delete cleanForm.pregnant_after_thirty
            delete cleanForm.lactation_months
            delete cleanForm.vag_bleeding
            delete cleanForm.breast_mass
          } else {
            if (cleanForm.ivs === "no") {
              delete cleanForm.pregnant_currently
              delete cleanForm.previous_pregnancies
              delete cleanForm.previous_pregnancies_qty
              delete cleanForm.pregnant_after_thirty
              delete cleanForm.lactation_months
              delete cleanForm.vag_bleeding
            } 
            if (cleanForm.previous_pregnancies === "no") {
              delete cleanForm.previous_pregnancies_qty
              delete cleanForm.pregnant_after_thirty
              delete cleanForm.lactation_months
            }
          }

          if (cleanForm.smoke === "no") {
            delete cleanForm.smoke_years
            delete cleanForm.smoke_qty_day
            delete cleanForm.ex_smoker
            delete cleanForm.ex_somker_years
          }

          if (cleanForm.alcohol === "no") {
            delete cleanForm.alcohol_frequency
            delete cleanForm.alcohol_amount
            delete cleanForm.alcohol_years
          }

          if (cleanForm.exercise === "no") {
            delete cleanForm.exercise_time
          }
          

          //console.log(JSON.stringify(cleanForm, null, 2))

          duppla({ url: 'questionnaire', method: 'POST', data: cleanForm })
            .then(response => {
              // console.log("*******************************************************************")
              // console.log("Almacenado: " + JSON.stringify(response.data, null, 2))
              setWithExpiration(RESULTS_SAVE_TAG, response.data);
              this.$router.push({ name: 'questionnaire-result', params: { param: response.data } })
            })
            .catch(error => {
              this.errorForm = true
              this.gTrackException('questionnaire -> submit: ' + error, true)
            })
            .finally(() => {
              this.savingForm = false
            })

        } else {
          currentForm.reportValidity();
          this.savingForm = false
        }
      }
    },
    contact () {
      // validar recapctah
      if(!this.loadingContactForm) {
        this.errorContactForm = false
        this.reCaptchaMissingContactForm = false
        if (this.$refs.contactForm.checkValidity()) {
          if (!this.contactForm.g_recaptcha_response) {
            this.reCaptchaMissingContactForm = true
            return
          }
          this.loadingContactForm = true

          let cleanForm = JSON.parse(JSON.stringify(this.contactForm));

          if (cleanForm.phone_country) {
            cleanForm.phone_country = [cleanForm.phone_country]
          }

          duppla({ url: 'contact', method: 'POST', data: cleanForm })
          .then(response => {
            this.gTrackEvent('contact', 'engagement', null)
            this.successContactForm = true
            //console.log(JSON.stringify(response.data, null, 2))
          })
          .catch(error => {
            this.errorContactForm = true
            this.$refs.recaptchaContactForm.reset();
            this.gTrackException('questionnaire -> contact: ' + error, false)
          })
          .finally( () => {
            this.loadingContactForm = false
            //no se requiere el reset debido a que el recaptcha desaparece
            //this.$refs.recaptchaContactForm.reset();
            this.contactForm.g_recaptcha_response = null
          })
        } else {
          this.$refs.contactForm.reportValidity();
        }
      }
    },
    getCountries () {
      duppla({ url: 'countries', method: 'GET' })
        .then(response => {
          this.countries = response.data
        })
    },
    getOrganization (uuid) {

      return new Promise((resolve) => {
        duppla({ url: 'organization?uuid=' + uuid, method: 'GET' })
          .then(response => {
            this.organization = response.data
            // console.log(JSON.stringify(this.organization, null, 2))
            this.loadingMedicalRecord = false
            this.validOrganization = true
          })
          .catch(() => {})
          .finally(() => {
            this.loadingMedicalRecord = false
            resolve()
          })
      })

    },
     generateAlphanumericId() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      return Array.from({length: 10}, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
    },
    startQuestionnaire() {
      if (this.organization) {
        this.validOrganizationAndStart = true
        heap.identify(this.generateAlphanumericId());
      }
    },
    directRelativeChange () {
      if (this.form.direct_relative === 'no' || this.form.direct_relative === 'idk') {
        if (this.form.direct_relatives) {
          this.form.direct_relatives.splice(0)
        }
      } else {
        if (this.form.direct_relatives.length === 0) { 
          this.addFormListItem("direct_relatives")
        }
      }
    },
    symptomChange () {
      // console.log('changing kps: ' + this.form.scale_kps)
      if (this.form.symptom_concern === 'no') {
        if (this.form.symptoms) {
          this.form.symptoms.splice(0)
        }
      } else {
        // console.log('this is the current length fo symtpoms:' + this.form.symptoms.length)
        if (this.form.symptoms.length === 0) { 
          this.addFormListItem("symptoms")
        }
      }
    },
    addFormListItem (list) {
      let item = {
        fields: {}
      }

      // select form inputs
      if (list === "direct_relatives") {
        item.fields.disease = null
        item.fields.relative = null
        // item.fields.alive = null
        item.fields.diagnosis_age = null
      }
      this.form[list].push(item)
    },
    removeFormListItem (list, index) {
      if(this.form[list][index].id) {
        if (!this.form[list + "_delete"]) {
          this.form[list + "_delete"] = []
        }
        this.form[list + "_delete"].push(this.form[list][index].id)
        this.form[list].splice(index, 1)
      } else {
        this.form[list].splice(index, 1)
      }
    },
    getLinkedLists () {
      let fields = '?'

      for (var i= 0; i<this.linkedLists.length; i++) {
        fields += '&fields%5B%5D=' + this.linkedLists[i]
      }
      // console.log('getLinkedLists: ' + fields)
      duppla({ url: 'my-medical-record' + fields, method: 'GET', data: null })
        .then(response => {
          // console.log(JSON.stringify(response.data.fields, null, 2))
          for (var i= 0; i<this.linkedLists.length; i++) {
            this.form[this.linkedLists[i]] = response.data.fields[this.linkedLists[i]]
            delete this.form[this.linkedLists[i] + "_delete"]
          }
        })
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    loadHbspt (uuid) {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src="https://js.hsforms.net/forms/embed/v2.js";
        document.body.appendChild(script);
        script.addEventListener("load", () => {
          //console.debug("hbspt script loaded")
          resolve(event);
        })
      })
    }
  },
  created () {
    let p1 = this.getOrganization(this.organizationUuid)  
    let p2 = this.loadHbspt()
    this.getCountries()
    const result = getWithExpiration(RESULTS_SAVE_TAG);
    console.log("🚀 ~ created ~ result:", result)
    if (result) {
      this.$router.push({ name: 'questionnaire-result', params: { param: result } });
    }
    Promise.all([p1, p2]).then(data => {
      if (window.hbspt && !this.validOrganization) {
        console.debug("hbspt valid, creating form")
        try{
          window.hbspt.forms.create({
            region: "na1",
            portalId: "39774904",
            formId: "a465be13-68e3-4c96-ae73-1dbccb8d2c1d",
            target: "#hubspotForm"
          })
        } catch(e){
          console.error("Creating hbspt form")
          console.error(e)
        }
      }
    })
  }
};
</script>
<style type="text/css">
  .dropzone .dz-preview .dz-image {
    width: 120px;
    height: 120px;
  }
  .ar *,
  .ar *:before,
  .ar *:after {
      -webkit-box-sizing: content-box !important;
      -moz-box-sizing: content-box !important;
      box-sizing: content-box !important;
  }
  /*
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
      opacity: 0;
  }
  */
  .btn_quest_mob{
    width:55px !important;
    height: 55px !important;
  }
   .btn_quest_mob .icons{
    width: 30px !important;
    height: 30px !important;
   }

   @media (max-width: 769px){
    .questionnarie_content label, .my_questionnaire p, .form-group .form-control, legend, .questionnarie_content .custom-select, .form-control, .questionnarie_content .input, .vue-monthly-picker .date-popover{
      font-size: 22px !important;
    }
    .questionnarie_content .card-title, .my_questionnaire .title{
      font-size: 30px !important;
    }
    .aumented_font {
      font-size: 22px !important;
    }
   }
</style>

<template>
  <div ref="init">
    <Navbar :isIcons="false" :navOptions="false" :signupOptions="false" :corporateOptions="false"/>
    
    <!--div class="back-to-home rounded d-none d-sm-block">
      <b-btn class="btn btn-icon btn-primary" @click.prevent="submitAndleave" :disabled="savingForm">
        <home-icon class="icons"></home-icon>
      </b-btn>
    </div-->
    <!--section class="bg-half bg-light w-100" style="padding-top: 50px; padding-bottom: 50px;">

      <div class="container">
          <div class="row">
              <div class="col-lg-12 my_questionnaire">
                <h4 class="title" v-if="wFirstStep">{{greeting}}</h4>
                  <p class="text-muted font-italic p-1 bg-light rounded" v-html="wSteps[wCurrentStep].text"></p>
              </div>
          </div>
      </div>
    </section>
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div-->
    <!-- Hero Start -->
    <section class="section pb-0 mt-md-0 mt-5 mb-5" v-if="!validOrganizationAndStart">
      <div class="container">
        <div class="row justify-content-center" v-if="loadingMedicalRecord">
          <div class="col-12 text-center">
            Estamos validando tu liga de acceso... <br/><br/>
            <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="Cargando..."></b-spinner>
          </div>
        </div>
        <span v-if="validOrganization  && !loadingMedicalRecord">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="section-title mb-1 pb-2">
                <h3 class="mb-4">¿Quieres saber cómo prevenir el cáncer en menos de 5 minutos? </h3>
                <p class="text-muted para-desc mx-auto mb-0">
                  Responde este cuestionario en <span class="text-primary font-weight-bold">60 segundos</span> y obtén un plan personalizado para reducir tu riesgo.<br/>
                </p>
              </div>
            </div>
            <div class="col-12 text-center">
              <p class="para-desc mx-auto mb-0">
                SIN COSTO PARA TI gracias a:
                <span v-if="!organization.fields.img_publicid || !organization.fields.img_version">
                  <br/><br/><h5>{{this.organization.fields.name}}</h5><br/>
                </span>
                <span v-if="organization.fields.img_publicid && organization.fields.img_version">
                  <br/><br/>
                  <cld-image cloudName="duppla" :publicId="organization.fields.img_publicid" :version="organization.fields.img_version" secure="true" class="mt-3 mb-3">
                    <cld-transformation quality="auto" fetchFormat="auto"/>
                  </cld-image>
                  <br/><br/>
                </span>
              <b-btn class="btn btn-soft-primary btn-lg" id="start-questionnaire" @click.prevent="startQuestionnaire" style="font-size: 19px !important">
                ¡Inicia aquí!
              </b-btn>
              </p>
            </div>
          </div>
        </span>
        <span v-if="!validOrganization && !loadingMedicalRecord">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="section-title">
                <h3 class="mb-4">Queremos vivir en un país donde nadie muere por cáncer</h3>
                <p class="text-muted para-desc mx-auto mb-0">
                  En <span class="text-primary font-weight-bold">Duppla</span>, hemos creado esta herramienta con un grupo de oncólogos y bioestadistas de reconocimiento global. En menos de 5 minutos una persona obtiene recomendaciones de prevención personalizadas a partir de su perfil de riesgo individual.
                </p>
              </div>
            </div>
            <div class="col-12 text-center mt-5">
              <p class="para-desc mx-auto mb-0">
                Este servicio está disponible mediante invitación únicamente.<br/>
              </p>
            </div>
            <div class="col-lg-5 col-md-6 col-10 mt-sm-0 pt-2 pt-sm-0 text-center">
              <p class="mt-4">¿Te interesaría hacerte el Checkup Digital de cáncer?</p>
              <div class="row">
                <div class="col-lg-12 mt-3" id="hubspotForm" v-once></div>
                <!--div class="col-lg-12">
                  <a href="https://www.duppla.doctor/es-mx/contacto/empresas" class="btn btn-soft-primary btn-block">Contáctanos
                  </a>
                </div-->
              </div>
              <!--form ref="contactForm" v-if="!successContactForm">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="foot-subscribe form-group">
                      <label>Déjanos tu email: <span class="text-danger">*</span></label>
                      <div class="position-relative">
                        <mail-icon class="fea icon-sm icons"></mail-icon>
                        <input type="email" v-model="contactForm.email" class="form-control pl-5 rounded" placeholder="Email: " required/>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <vue-recaptcha :sitekey="reCaptchaSiteKey" :loadRecaptchaScript="true" @verify="reCaptchaVerifiedContactForm" @expired="reCaptchaExpiredContactForm" ref="recaptchaContactForm"></vue-recaptcha>
                  </div>
                  <div class="col-lg-12 mt-3">
                    <b-btn class="btn btn-soft-primary btn-block" @click.prevent="contact" :disabled="loadingContactForm">
                      {{(loadingContactForm?'Enviando...':'Enviar')}}
                    </b-btn>
                  </div>
                  <div class="col-md-12 mt-3" v-if="errorContactForm">
                    <div class="alert alert-danger" role="alert">Ups... ha ocurrido un error inesperado, vuelve a intentar mas tarde</div>
                  </div>
                  <div class="col-md-12 mt-3" v-if="reCaptchaMissingContactForm">
                    <div class="alert alert-danger" role="alert">Asegurate de resolver el CAPTCHA para poder continuar</div>
                  </div>
                </div>
              </form-->
            </div>
          </div>
          <!--div class="row justify-content-center">
            <div class="col-lg-5 col-md-6 col-10 mt-sm-0 pt-2 pt-sm-0 text-center" v-if="successContactForm">
              <div class="alert alert-success" role="alert">¡Gracias por tu registro! Un representante de Duppla te contactará pronto</div>
            </div>
          </div-->
        </span>
      </div>
    </section>
    <section class="bg-invoice bg-light" v-if="validOrganizationAndStart">
      <div class="container" ref="wizard">
        <!--div class="row justify-content-center">
          <div class="col-lg-10 col-12 alert alert-secondary" role="alert">
            <h4 class="title" v-if="wFirstStep">{{greeting}}</h4>
            <p class="text-justify" v-html="wSteps[wCurrentStep].text"></p>
          </div>
        </div-->
        <div class="row justify-content-center">
          <div class="col-lg-10 col-12">
            <div class="progress-box">
              <h5 class="title text-muted">Progreso <span class="d-none d-md-inline">del cuestionario</span><span class="float-right">Paso {{wCurrentStep + 1}} de {{wTotalSteps + 1}}</span></h5>
              <div class="progress">
                <div class="progress-bar position-relative bg-primary" v-bind:style="{width: wProgress + '%'}">
                  <div class="progress-value d-block text-muted h6">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-10 col-12">
            <div class="card login_page shadow rounded shadow-lg p-sm-1 p-md-4 p-lg-4 mb-5">
              <div class="card-body p-sm-1 questionnarie_content" >
                  <div class="row justify-content-center" v-if="loadingMedicalRecord">
                    <!--Spinner name="heart" color="#2F55D4"/-->
                    <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="Cargando..."></b-spinner>
                  </div>
                
                  <form class="login-form" ref="form_init" v-if="wStepName(wCurrentStep) === 'init' && !loadingMedicalRecord">
                    <!-- <h4 class="card-title">Información de Contacto</h4>
                    <hr/> -->
                    <div class="row">
                      <div class="col-md-12 mt-2 mb-4">
                        <h4 class="title" v-if="wFirstStep">{{greeting}}</h4>
                        <p class="text-justify" v-html="wSteps[wCurrentStep].text"></p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Correo electrónico <span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <mail-icon class="fea icon-sm icons"></mail-icon>
                            <input type="email" class="form-control pl-5" placeholder="Email" v-model="form.contact_email" id="contact_email" required/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Edad <span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <hash-icon class="fea icon-sm icons"></hash-icon>
                           <input required type="number" class="form-control pl-5" placeholder="" v-model="form.user_age" name="user_age" id="user_age" min="1" max="150"/>
                          </div>
                        </div>
                      </div>
                        <div class="col-md-5">
                        <div class="form-group" id="patientSex">
                          <label>Sexo de nacimiento <span class="text-danger">*</span></label>
                          <div class="ml-md-3 position-relative custom-control custom-radio">
                            <input type="radio" id="female" name="patientSex" class="custom-control-input patientSex" value="female" v-model="form.patient_sex" required/>
                            <label class="custom-control-label" for="female">Mujer</label>
                          </div>
                          <div class="ml-md-3 position-relative custom-control custom-radio">
                            <input type="radio" id="male" name="patientSex" class="custom-control-input patientSex" value="male" v-model="form.patient_sex" required/>
                            <label class="custom-control-label" for="male">Hombre</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <form class="login-form" ref="form_symptoms_step" v-if="wStepName(wCurrentStep) === 'symptoms_step'">
                    <div class="row">
                      <div class="col-md-12 mt-2 mb-4">
                        <h4 class="title" v-if="wFirstStep">{{greeting}}</h4>
                        <p class="text-justify" v-html="wSteps[wCurrentStep].text"></p>
                      </div>
                    </div>
                    <!-- <h4 class="card-title">Sintomas</h4>
                    <hr/> -->
                    <div class="row">
                        <!-- <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Has tenido diagnóstico de cáncer? " >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="cancerDiagnosisYes" name="cancer_diagnosis" class="custom-control-input" value="yes" v-model="form.cancer_diagnosis" required/>
                            <label class="custom-control-label" for="cancerDiagnosisYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="cancerDiagnosisNo" name="cancer_diagnosis" class="custom-control-input" value="no" v-model="form.cancer_diagnosis" required/>
                            <label class="custom-control-label" for="cancerDiagnosisNo">No</label>
                          </div>
                        </b-form-group>
                      </div> -->
                       <!-- <div class="col-md-12" v-if="form.cancer_diagnosis === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="6" content-cols-sm="6" content-cols-lg="6" label="¿Cuál es el diagnóstico?" >
                          <input type="text" class="form-control" placeholder="" name="diagnosis_name" required v-model="form.diagnosis_name"/>
                        </b-form-group>
                      </div> -->
                       <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="symptom_concern" label="¿Padeces algún síntoma o malestar de preocupación?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="symptomConcernYes" name="symptom_concern" class="custom-control-input" value="yes" v-model="form.symptom_concern" required @change="symptomChange"/>
                            <label class="custom-control-label" for="symptomConcernYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="symptomConcernNo" name="symptom_concern" class="custom-control-input" value="no" v-model="form.symptom_concern" required @change="symptomChange"/>
                            <label class="custom-control-label" for="symptomConcernNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <span v-if="form.symptom_concern === 'yes'">
                        <div class="col-md-12 form-inline mb-4">
                          IMPORTANTE: este cuestionario calcula el riesgo de personas sin síntomas ni preexistencia de cáncer.<br/>
                          Por favor especifica cada síntoma que hayas presentado, así como el mes y año aproximado de aparición.
                          <p class="text-muted"><i>Ej. Vómitos, Náuseas, Mareos, Desmayos, Dolores, Inmovilidad, etc…</i></p>
                        </div>
                        <span v-for="(item, index) in form.symptoms">
                          <div class="col-md-12 form-inline mb-4" v-if="item.action != 'delete'">
                            <label class="sr-only" :for="'sy1_'+index">Síntoma/Malestar</label>
                            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'sy1_'+index" placeholder="Síntoma/Malestar" required v-model="item.fields.description" style="min-width: 200px;" />
                            <label class="sr-only" :for="'sy2_'+index">Fecha</label>
                            <!--input type="date" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'sy2_'+index" placeholder="dd/mm/yyyy" v-model="item.fields.date" required :max="currDate"/-->
                            <vue-monthly-picker v-model="item.fields.date" :monthLabels="monthLabels" placeHolder="-- Mes y Año --" dateFormat="MMM YYYY" selectedBackgroundColor="#2f55d4" required class="mr-2 mb-2 mb-sm-0" :max="currDate"></vue-monthly-picker>
                            <a href="#" class="btn btn-icon btn-pills btn-outline-danger" v-if="form.symptoms.length > 1" @click.prevent="removeFormListItem('symptoms', index)"><x-icon class="fea icon-sm"></x-icon></a>
                          </div>
                        </span>
                        <div class="col-md-12 mb-4">
                          <!--a href="#" class="btn btn-icon btn-pills btn-primary" @click.prevent="addFormListItem('symptoms')"><plus-icon class="fea icon-sm"></plus-icon></a-->
                          <b-btn class="btn btn-primary ml-3" id="add-symptom" @click.prevent="addFormListItem('symptoms')">
                            Agregar Síntoma
                          </b-btn>
                        </div>
                      </span>
                    </div>
                  </form>

                  <form class="login-form" ref="form_ahf" v-if="wStepName(wCurrentStep) === 'ahf'">
                    <div class="row">
                      <div class="col-md-12 mt-2 mb-4">
                        <h4 class="title" v-if="wFirstStep">{{greeting}}</h4>
                        <p class="text-justify" v-html="wSteps[wCurrentStep].text"></p>
                      </div>
                    </div>
                    <!-- <h4 class="card-title">Antecedentes Heredo-Familiares</h4>
                    <hr/> -->
                    <div class="row">
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="direct_relative" label="¿Tienes antecedentes de cáncer en la familia?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="direct_relativeYes" name="direct_relative" class="custom-control-input" value="yes" v-model="form.direct_relative" required @change="directRelativeChange"/>
                            <label class="custom-control-label" for="direct_relativeYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="direct_relativeNo" name="direct_relative" class="custom-control-input" value="no" v-model="form.direct_relative" required @change="directRelativeChange"/>
                            <label class="custom-control-label" for="direct_relativeNo">No</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="direct_relativeIdk" name="direct_relative" class="custom-control-input" value="idk" v-model="form.direct_relative" required @change="directRelativeChange"/>
                            <label class="custom-control-label" for="direct_relativeIdk">No lo sé</label>
                          </div>
                        </b-form-group>
                      </div>
                      <span v-if="form.direct_relative === 'yes'">
                        <div class="col-md-12 form-inline mb-4">
                          Por favor especifica y agrega cuantos familiares sean necesarios:
                          <p class="text-muted"><i></i></p>
                        </div>
                        <span v-for="(item, index) in form.direct_relatives">
                          <!--hr v-if="index > 0"/-->
                          <div class="col-md-12 form-inline mb-4" >
                            <label class="sr-only" :for="'dr1_'+index">Cáncer detectado</label>
                            <b-select class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'dr1_'+index" v-model="item.fields.disease" required>
                              <template #first>
                                <b-select-option :value="null" disabled>-- Cáncer detectado --</b-select-option>
                              </template>

                              <!-- These options will appear after the ones from 'options' prop -->
                              <b-select-option value="colon">Colon</b-select-option>
                              <b-select-option value="endometrio">Endometrio</b-select-option>
                              <b-select-option value="mama">Cáncer de Mama</b-select-option>
                              <b-select-option value="prostata">Próstata</b-select-option>
                              <b-select-option value="pancreas">Páncreas</b-select-option>
                              <b-select-option value="ovario">Ovario</b-select-option>
                              <b-select-option value="cancer-otro">Otro/No recuerdo cual</b-select-option>
                            </b-select>

                            <label class="sr-only" :for="'dr2_'+index">¿Quién?</label>
                            <b-select class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'dr2_'+index" v-model="item.fields.relative" required>
                              <template #first>
                                <b-select-option :value="null" disabled>-- ¿Quién? --</b-select-option>
                              </template>

                              <!-- These options will appear after the ones from 'options' prop -->
                              <b-select-option value="grandparent">Abuelo(a)</b-select-option>
                              <b-select-option value="father">Padre</b-select-option>
                              <b-select-option value="mother">Madre</b-select-option>
                              <b-select-option value="uncle">Tío(a)</b-select-option>
                              <b-select-option value="brother">Hermano</b-select-option>
                              <b-select-option value="sister">Hermana</b-select-option>
                              <b-select-option value="cousin">Primo(a)</b-select-option>

                            </b-select>

                            <label class="sr-only" :for="'dr4_'+index">¿Edad de Diagnóstico?</label>
                            <b-select class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'dr4_'+index" v-model="item.fields.diagnosis_age" required>
                              <template #first>
                                <b-select-option :value="null" disabled>-- ¿Edad de Diagnóstico? --</b-select-option>
                              </template>

                              <!-- These options will appear after the ones from 'options' prop -->
                              <b-select-option value="0-39">0-39</b-select-option>
                              <b-select-option value="40-44">40-44</b-select-option>
                              <b-select-option value="45-49">45-49</b-select-option>
                              <b-select-option value="50+">50+</b-select-option>

                            </b-select>
                            
                            <a href="#" class="btn btn-icon btn-pills btn-outline-danger" v-if="form.direct_relatives.length > 1" @click.prevent="removeFormListItem('direct_relatives', index)"><x-icon class="fea icon-sm"></x-icon></a>
                          </div>
                        </span>
                        <div class="col-md-12 mb-4">
                          <b-btn class="btn btn-primary" id="add_direct_relatives" @click.prevent="addFormListItem('direct_relatives')">
                            Agregar Familiar
                          </b-btn>
                        </div>
                      </span>
                    </div>
                  </form>

                  <form class="login-form" ref="form_agine" v-if="wStepName(wCurrentStep) === 'agine'">
                    <!-- <h4 class="card-title">Antecedente Ginecobstétrico</h4>
                    <hr/> -->
                    <div class="row">
                      <div class="col-md-12 mt-2 mb-4">
                        <h4 class="title" v-if="wFirstStep">{{greeting}}</h4>
                        <p class="text-justify" v-html="wSteps[wCurrentStep].text"></p>
                      </div>
                    </div>
                    <div class="row">
                       <div class="col-md-12" v-if="form.user_age >= 40">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="6" label="Edad de primera menstruación:">
                         <input required type="number" class="form-control" placeholder="" v-model="form.f_menstruation_age" name="f_menstruation_age" id="f_menstruation_age" min="1" max="99"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" id="ivs" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Has tenido relaciones sexuales?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="ivsYes" name="ivs" class="custom-control-input" value="yes" v-model="form.ivs" required/>
                            <label class="custom-control-label" for="ivsYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="ivsNo" name="ivs" class="custom-control-input" value="no" v-model="form.ivs" required/>
                            <label class="custom-control-label" for="ivsNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.ivs === 'yes'">
                        <b-form-group id="vag_bleeding" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Has experimentado algún sangrado vaginal fuera de tu período menstrual habitual?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="vag_bleedingYes" name="vag_bleeding" class="custom-control-input" value="yes" v-model="form.vag_bleeding" required>
                            <label class="custom-control-label" for="vag_bleedingYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="vag_bleedingNo" name="vag_bleeding" class="custom-control-input" value="no" v-model="form.vag_bleeding" required/>
                            <label class="custom-control-label" for="vag_bleedingNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.user_age >= 40">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="4" label="Edad de tu última o más reciente menstruación:" >
                          <!--input type="date" class="form-control" placeholder="dd/mm/yyyy" v-model="form.l_menstruation_age" :max="currDate"/-->
                          <!--input type="number" class="form-control" placeholder="Edad en años" v-model="form.l_menstruation_age" :min="form.f_menstruation_age" max="100"/-->
                          <input required type="number" class="form-control" placeholder="" id="last_menstruation_age" v-model="form.l_menstruation_age" name="l_menstruation_age" min="form.f_menstruation_age" max="99"/>
                          <!--small tabindex="-1" class="form-text text-muted">
                            Dejar en blanco en caso de continuar menstruando
                          </small-->
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.user_age >= 40">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="contraceptives" label="¿Has utilizado terapias de sustitución hormonal por más de 5 años?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="contraceptivesYes" name="contraceptives" class="custom-control-input" value="yes" v-model="form.contraceptives" required/>
                            <label class="custom-control-label" for="contraceptivesYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="contraceptivesNo" name="contraceptives" class="custom-control-input" value="no" v-model="form.contraceptives" required/>
                            <label class="custom-control-label" for="contraceptivesNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.ivs === 'yes' && form.user_age >= 40">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="pregnant_currently" label="¿Estás embarazada actualmente?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="pregnant_currentlyYes" name="pregnant_currently" class="custom-control-input" value="yes" v-model="form.pregnant_currently" required>
                            <label class="custom-control-label" for="pregnant_currentlyYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="pregnant_currentlyNo" name="pregnant_currently" class="custom-control-input" value="no" v-model="form.pregnant_currently" required/>
                            <label class="custom-control-label" for="pregnant_currentlyNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.ivs === 'yes' && form.user_age >= 40">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="previous_pregnancies" label="¿Has tenido embarazos previos?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="previous_pregnanciesYes" name="previous_pregnancies" class="custom-control-input" value="yes" v-model="form.previous_pregnancies" required>
                            <label class="custom-control-label" for="previous_pregnanciesYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="previous_pregnanciesNo" name="previous_pregnancies" class="custom-control-input" value="no" v-model="form.previous_pregnancies" required/>
                            <label class="custom-control-label" for="previous_pregnanciesNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.previous_pregnancies === 'yes' && form.ivs === 'yes' && form.user_age >= 40">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="3" label="¿Cuántos embarazos previos has tenido?" >
                          <input type="number" class="form-control" placeholder="Cuántos Embarazos" id="previous_pregnancies_qty" v-model="form.previous_pregnancies_qty" required min="1" max="30"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.previous_pregnancies === 'yes' && form.ivs === 'yes' && form.user_age >= 40">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="pregnant_after_thirty" label="¿Has tenido embarazos después de los 30?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="pregnant_after_thirtyYes" name="pregnant_after_thirty" class="custom-control-input" value="yes" v-model="form.pregnant_after_thirty" required>
                            <label class="custom-control-label" for="pregnant_after_thirtyYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="pregnant_after_thirtyNo" name="pregnant_after_thirty" class="custom-control-input" value="no" v-model="form.pregnant_after_thirty" required/>
                            <label class="custom-control-label" for="pregnant_after_thirtyNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <!-- <div class="col-md-12" v-if="form.previous_pregnancies === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="3" label="¿Cuántos partos ha tenido?" >
                          <input type="number" class="form-control" placeholder="Cuántos Partos" v-model="form.birth_qty" required min="0" :max="form.previous_pregnancies_qty"/>
                        </b-form-group>
                      </div> -->
                      <!-- <div class="col-md-12" v-if="form.previous_pregnancies === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="3" label="¿Cuántas cesáreas ha tenido?" >
                          <input type="number" class="form-control" placeholder="Cuántas Cesáreas" v-model="form.c_section_qty" required min="0" :max="form.previous_pregnancies_qty"/>
                        </b-form-group>
                      </div> -->
                      <!-- <div class="col-md-12" v-if="form.previous_pregnancies === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="3" label="¿Cuántos abortos ha tenido?" >
                          <input type="number" class="form-control" placeholder="Cuántos Abortos" v-model="form.abortion_qty" required min="0" :max="form.previous_pregnancies_qty - form.birth_qty"/>
                        </b-form-group>
                      </div> -->
                      <div class="col-md-12" v-if="form.previous_pregnancies === 'yes' && form.ivs === 'yes' && form.user_age >= 40">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="3" label="¿Cuántos meses has lactado en total?" >
                          <input type="number" class="form-control" placeholder="Total Meses" v-model="form.lactation_months" id="lactation_months" required min="0" max="600"/>
                          <small tabindex="-1" class="form-text text-muted">
                            Ejemplo: primer bebé 4 meses, segundo bebé 2 meses, tu  respuesta sería 6 meses.
                          </small>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.user_age >= 40">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="breast_mass" label="¿Has identificado recientemente alguna masa o bolita en tu mama que no estaba presente antes?">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="breast_massYes" name="breast_mass" class="custom-control-input" value="yes" v-model="form.breast_mass" required>
                            <label class="custom-control-label" for="breast_massYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="breast_massNo" name="breast_mass" class="custom-control-input" value="no" v-model="form.breast_mass" required/>
                            <label class="custom-control-label" for="breast_massNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                  </form>

                  <!-- Antecedentes Personales No Patológicos -->
                  <form class="login-form" ref="form_apnp" v-if="wStepName(wCurrentStep) === 'apnp'">
                    <div class="row">
                      <div class="col-md-12 mt-2 mb-4">
                        <h4 class="title" v-if="wFirstStep">{{greeting}}</h4>
                        <p class="text-justify" v-html="wSteps[wCurrentStep].text"></p>
                      </div>
                    </div>
                    <!-- <h4 class="card-title">Antecedentes Personales No Patológicos</h4>
                    <hr/> -->
                    <div class="row">
                          <!--Alergias-->
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Fumas o has fumado?" id="smoke">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="smokeYes" name="smoke" class="custom-control-input" value="yes" v-model="form.smoke" required/>
                            <label class="custom-control-label" for="smokeYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="smokeNo" name="smoke" class="custom-control-input" value="no" v-model="form.smoke" required/>
                            <label class="custom-control-label" for="smokeNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.smoke === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuántos años fumaste o has fumado?" >
                          <input type="number" class="form-control" placeholder="Cantidad de Años" v-model="form.smoke_years" id="smoke_years" required min="1" max="100"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.smoke === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuántos cigarros al día?" >
                          <input type="number" class="form-control" placeholder="Cantidad de Cigarros" v-model="form.smoke_qty_day" id="smoke_qty_day" required min="1" max="500"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.smoke === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Eres exfumador?" id="ex_smoker">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="ex_smokerYes" name="ex_smoker" class="custom-control-input" value="yes" v-model="form.ex_smoker" required/>
                            <label class="custom-control-label" for="ex_smokerYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="ex_smokerNo" name="ex_smoker" class="custom-control-input" value="no" v-model="form.ex_smoker" required/>
                            <label class="custom-control-label" for="ex_smokerNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.ex_smoker === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Hace cuántos años dejaste de fumar?" description="Si es entre 1 y 11 meses indica 1 año">
                          <input type="number" class="form-control" placeholder="Cantidad de Años" v-model="form.ex_somker_years" id="ex_somker_years" required min="1" max="100"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="cough_extended" label="¿Has presentado tos por más de 2 meses consecutivos?">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="cough_extendedYes" name="cough_extended" class="custom-control-input" value="yes" v-model="form.cough_extended" required/>
                            <label class="custom-control-label" for="cough_extendedYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="cough_extendedNo" name="cough_extended" class="custom-control-input" value="no" v-model="form.cough_extended" required/>
                            <label class="custom-control-label" for="cough_extendedNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="cough_blood" label="¿Has presentado tos con sangre?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="cough_bloodYes" name="cough_blood" class="custom-control-input" value="yes" v-model="form.cough_blood" required/>
                            <label class="custom-control-label" for="cough_bloodYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="cough_bloodNo" name="cough_blood" class="custom-control-input" value="no" v-model="form.cough_blood" required/>
                            <label class="custom-control-label" for="cough_bloodNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="alcohol" label="¿Bebes alcohol?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="alcoholYes" name="alcohol" class="custom-control-input" value="yes" v-model="form.alcohol" required/>
                            <label class="custom-control-label" for="alcoholYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="alcoholNo" name="alcohol" class="custom-control-input" value="no" v-model="form.alcohol" required/>
                            <label class="custom-control-label" for="alcoholNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.alcohol === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Con qué frecuencia?" >
                          <b-select class="form-control" v-model="form.alcohol_frequency" id="alcohol_frequency" required>
                              <template #first>
                                <b-select-option :value="null" disabled>-- Frecuencia --</b-select-option>
                              </template>

                              <!-- These options will appear after the ones from 'options' prop -->
                              <b-select-option value="daily">Diario</b-select-option>
                              <b-select-option value="weekend">Fines de Semana</b-select-option>
                              <b-select-option value="social">Social</b-select-option>
                            </b-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.alcohol === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Qué cantidad (copas) en esa frecuencia?" >
                          <input type="number" class="form-control" placeholder="Cantidad de Copas" v-model="form.alcohol_amount" id="alcohol_amount" required min="1" max="100"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.alcohol === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuántos años has bebido así?" >
                          <input type="number" class="form-control" placeholder="Cantidad de Años" v-model="form.alcohol_years" id="alcohol_years" required min="1" max="100"/>
                        </b-form-group>
                      </div>

                      <!--Sleep-->
                      <!--div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Considera que sus horas de sueño son buenas?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="sleepYes" name="sleep" class="custom-control-input" value="yes" v-model="form.sleep" required/>
                            <label class="custom-control-label" for="sleepYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="sleepNo" name="sleep" class="custom-control-input" value="no" v-model="form.sleep" required/>
                            <label class="custom-control-label" for="sleepNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Por qué?" >
                          <input type="text" class="form-control" placeholder="" required v-model="form.sleep_description"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuántas horas promedio de sueño cada 24 horas?" >
                          <input type="number" class="form-control" placeholder="ej. 8" v-model="form.sleep_hours" required min="1" max="24"/>
                        </b-form-group>
                      </div-->
                        <!--Excercise-->
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="exercise" label="¿Haces ejercicio o tienes actividad física 3 o más días por semana?">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="exerciseYes" name="exercise" class="custom-control-input" value="yes" v-model="form.exercise" required/>
                            <label class="custom-control-label" for="exerciseYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="exerciseNo" name="exercise" class="custom-control-input" value="no" v-model="form.exercise" required/>
                            <label class="custom-control-label" for="exerciseNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.exercise === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="exercise_time" label="¿Haces más de 65 minutos de ejercicio intenso o 150 minutos de ejercicio moderado a la semana?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="exercise_timeYes" name="exercise_time" class="custom-control-input" value="yes" v-model="form.exercise_time" required/>
                            <label class="custom-control-label" for="exercise_timeYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="exercise_timeNo" name="exercise_time" class="custom-control-input" value="no" v-model="form.exercise_time" required/>
                            <label class="custom-control-label" for="exercise_timeNo">No</label>
                          </div>
                        </b-form-group>
                      </div>

                      <!-- Eating -->
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Qué describe mejor tu dieta típica?" >
                          <b-select class="form-control" v-model="form.eating_habit" required>
                            <template #first>
                              <b-select-option :value="null" id="eating_habit"disabled>-- Tipo de Dieta --</b-select-option>
                            </template>

                            <b-select-option value="normal">Normal</b-select-option>
                            <b-select-option value="vegetarian">Vegetariana</b-select-option>
                            <b-select-option value="hyposodic">Hiposódica (Baja en Sodio)</b-select-option>
                            <b-select-option value="hypofat">Hipograsa (Baja en Grasa)</b-select-option>
                            <b-select-option value="diabetic">Para Diabéticos</b-select-option>
                          </b-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="evacuation_changes" label="¿Has experimentado cambios recurrentes y de forma prolongada en tus hábitos de evacuación?">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="evacuation_changesYes" name="evacuation_changes" class="custom-control-input" value="yes" v-model="form.evacuation_changes" required/>
                            <label class="custom-control-label" for="evacuation_changesYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="evacuation_changesNo" name="evacuation_changes" class="custom-control-input" value="no" v-model="form.evacuation_changes" required/>
                            <label class="custom-control-label" for="evacuation_changesNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <!-- Indirect Relatives -->
                      <!--div class="col-md-12">
                        <hr/>
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Tiene familiares indirectos (tíos, abuelos, primos) que hayan padecido cáncer, algún tumor o alguna enfermedad catastrófica?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="indirect_relativeYes" name="indirect_relative" class="custom-control-input" value="yes" v-model="form.indirect_relative" required/>
                            <label class="custom-control-label" for="indirect_relativeYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="indirect_relativeNo" name="indirect_relative" class="custom-control-input" value="no" v-model="form.indirect_relative" required/>
                            <label class="custom-control-label" for="indirect_relativeNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.indirect_relative === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Quiénes?">
                          <input type="text" class="form-control" placeholder="ej. Tío, Abuelo, Primo, etc..." required v-model="form.indirect_relative_who"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.indirect_relative === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Qué enfermedades tuvieron?" >
                          <input type="text" class="form-control" placeholder="Ej. Cáncer, Tumores, Cardiopatías, etc..." required v-model="form.indirect_relative_disease"/>
                        </b-form-group>
                      </div-->
                    </div>
                  </form>

                  <!-- comorbilidades peso y altura -->
                  <form class="login-form" ref="form_comorbidities" v-if="wStepName(wCurrentStep) === 'comorbidities'">
                    <div class="row">
                      <div class="col-md-12 mt-2 mb-4">
                        <h4 class="title" v-if="wFirstStep">{{greeting}}</h4>
                        <p class="text-justify" v-html="wSteps[wCurrentStep].text"></p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Peso<span class="text-danger">*</span></label>
                          <small tabindex="-1" class="form-text text-muted">
                            (en kilogramos sin decimales)
                          </small>
                          <div class="position-relative">
                            <hash-icon class="fea icon-sm icons"></hash-icon>
                            <input type="number" class="form-control pl-5" placeholder="ej. 65" v-model="form.weight" id="weight" required min="40" max="200"/>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Altura<span class="text-danger">*</span></label>
                          <small tabindex="-1" class="form-text text-muted">
                            (en centímetros sin decimales)
                          </small>
                          <div class="position-relative">
                            <hash-icon class="fea icon-sm icons"></hash-icon>
                            <input type="number" class="form-control pl-5" placeholder="ej. 175" v-model="form.height" id="height" required min="140" max="210"/>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="diabetes" label="¿Tienes  diabetes?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="diabetesYes" name="diabetes" class="custom-control-input" value="yes" v-model="form.diabetes" required/>
                            <label class="custom-control-label" for="diabetesYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="diabetesNo" name="diabetes" class="custom-control-input" value="no" v-model="form.diabetes" required/>
                            <label class="custom-control-label" for="diabetesNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" id="hiperlipidemia" label="¿Tienes colesterol/triglicéridos altos (hiperlipidemia)?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="hiperlipidemiaYes" name="hiperlipidemia" class="custom-control-input" value="yes" v-model="form.hiperlipidemia" required/>
                            <label class="custom-control-label" for="hiperlipidemiaYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="hiperlipidemiaNo" name="hiperlipidemia" class="custom-control-input" value="no" v-model="form.hiperlipidemia" required/>
                            <label class="custom-control-label" for="hiperlipidemiaNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                  </form>

                  <form class="login-form" ref="form_end" v-if="wStepName(wCurrentStep) === 'end'">
                    <div class="row justify-content-center">
                      <div class="col-md-10">
                          <p class="" style="text-align: justify; text-justify: inter-word !important;">
                            ¡Felicidades! <br/>
                            Has completado el test en línea de cáncer y ahora puedes tener un seguimiento personalizado en tu prevención.
                          </p>
                      </div>
                      <div class="col-md-10">
                          <b-form-group label="¿Estás de acuerdo en que mantengamos contacto directo contigo para lograrlo?" id="opt_advice" >
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" id="opt_adviceYes" name="opt_advice" class="custom-control-input" value="yes" v-model="form.opt_advice" required />
                              <label class="custom-control-label" for="opt_adviceYes">Sí</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" id="opt_adviceNo" name="opt_advice" class="custom-control-input" value="no" v-model="form.opt_advice" required />
                              <label class="custom-control-label" for="opt_adviceNo">No</label>
                            </div>
                            <small tabindex="-1" class="form-text text-muted" v-if="form.opt_advice === 'yes'">
                              Al seleccionar "Si" aceptas ser contactado por medio de Whatsapp
                            </small>
                          </b-form-group>
                      </div>
                      <div class="col-md-4" v-if="form.opt_advice === 'yes'">
                        <div class="form-group">
                          <label>Lada de Contacto <span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <phone-forwarded-icon class="fea icon-sm icons"></phone-forwarded-icon>
                            <b-select class="form-control pl-5" v-model="form.contact_phone_country" name="contact_phone_country" at-type="lookup" :options="countries" required text-field="textLada" value-field="value" >
                              <template #first>
                                <b-select-option :value="null" disabled>-- Lada --</b-select-option>
                              </template>
                            </b-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="form.opt_advice === 'yes'">
                        <div class="form-group">
                          <label>Teléfono de Contacto <span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <phone-icon class="fea icon-sm icons"></phone-icon>
                            <input type="tel" class="form-control pl-5" placeholder="Teléfono de 10 dígitos" v-model="form.contact_phone" id="contact_phone" name="contact_phone" required maxlength="10" minlength="10" pattern="[0-9]{10}"/>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-10 mt-3">
                          <p class="" style="text-align: justify; text-justify: inter-word !important;">
                            Conoce tus resultados y recomendaciones personalizadas aceptando nuestros términos y condiciones así como la política de privacidad:
                          </p>
                      </div>
                      <div class="col-md-10 mt-3">
                        <div class="form-group">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customCheck1"
                              required
                            />
                            <label class="custom-control-label" for="customCheck1">
                              Acepto los <a href="/terminos/" target="_blank" class="text-primary">Términos y Condiciones</a>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-10">
                        <div class="form-group">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customCheck2"
                              required
                            />
                            <label class="custom-control-label" for="customCheck2">
                              Acepto el <a href="/privacidad/" target="_blank" class="text-primary">Aviso de Privacidad</a>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-10">
                        <div class="form-group">
                        <!--div class="g-recaptcha" data-sitekey="6Le7NUIdAAAAAHPA0ML0rQH7N2f3vR53Q_G4f2NT"></div-->
                          <vue-recaptcha :sitekey="reCaptchaSiteKey" :loadRecaptchaScript="true" @verify="reCaptchaVerified" @expired="reCaptchaExpired" ref="recaptcha"></vue-recaptcha>
                        </div>
                      </div>
                      <div class="col-md-10 mt-3" v-if="reCaptchaMissing">
                        <div class="alert alert-danger" role="alert">Asegurate de resolver el CAPTCHA para poder continuar</div>
                      </div>
                    </div>
                  </form>
                <hr/>
                <b-row align-h="between" class="row mt-5" v-if="!loadingMedicalRecord">
                  <b-col class="text-left col-5 col-md-4 col-lg-3">
                    <b-btn class="btn btn-primary btn-block d-none d-md-block" :id="'back-'+wStepName(wCurrentStep)" @click.prevent="previousStep" :disabled="savingForm" v-if="!wFirstStep">
                      <arrow-left-icon class="fea icon-sm"></arrow-left-icon> Anterior
                    </b-btn>
                    <b-btn  class="btn btn-primary btn-icon btn-pills d-md-none btn-lg btn_quest_mob" @click.prevent="previousStep" :disabled="savingForm" v-if="!wFirstStep">
                      <arrow-left-icon class="icons"></arrow-left-icon>
                    </b-btn>
                  </b-col>
                  <b-col class="text-right col-5 col-md-4 col-lg-3" v-if="!wLastStep">
                    <b-btn class="btn btn-primary btn-block d-none d-md-block" :id="'next-'+wStepName(wCurrentStep)" @click.prevent="nextStep" :disabled="savingForm">
                      <!--span v-if="wFirstStep">Inicia</span--> <span>Siguiente <arrow-right-icon class="fea icon-sm"></arrow-right-icon></span>
                    </b-btn>
                    <b-btn class="btn btn-primary btn-icon btn-pills d-md-none btn-lg btn_quest_mob" @click.prevent="nextStep" :disabled="savingForm">
                      <arrow-right-icon class="icons"></arrow-right-icon>
                    </b-btn>
                  </b-col>
                  <b-col class="text-right col-7 col-md-4 col-lg-3" v-if="wLastStep">
                    <b-btn id="ver-resultados" class="btn btn-success btn-block" @click.prevent="submit" :disabled="savingForm">
                      {{ (savingForm?'Procesando...':'Ver resultados') }}
                    </b-btn>
                  </b-col>
                </b-row>
                <b-row>
                  <div class="col-md-12 mt-3" v-if="errorForm">
                    <div class="alert alert-danger" role="alert">Ups... ha ocurrido un error inesperado, vuelve a intentar mas tarde</div>
                  </div>
                </b-row>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!--Switcher /-->
    <!-- <whatsapp-widget source="Medical Record Wizard Widget"/> -->
    <!--Footer/-->
  </div>
</template>
  
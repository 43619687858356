const EXPIRATION_TIME = Number(process.env.VUE_APP_EXPIRATION_TIME);

export function setWithExpiration(key, value) {
	const now = new Date();
	const expiration = now.setMinutes(now.getMinutes() + EXPIRATION_TIME);
	const item = {
		value: value,
		expiration: expiration,
	};
	localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiration(key) {
	console.log("🚀 ~ getWithExpiration ~ key:", key)
	const itemStr = localStorage.getItem(key);
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
	const expiration = new Date(item.expiration);
	if (now > expiration) {
		localStorage.clear();
		if (esRutaRaizConId()) {
			return null;
		} else {
			window.location.assign("https://www.duppla.doctor/beneficiarios");
		}
		return null;
	}
	return item.value;
}

function esRutaRaizConId() {
	const esRaiz = window.location.pathname === "/";
	const parametros = new URLSearchParams(window.location.search);
	const tieneId = parametros.has("id");
	return esRaiz && tieneId;
}
